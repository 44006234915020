import React from "react";
import colorSharp from "../assets/img/about-me-back.svg";
import profileImage from "../assets/img/profile-pic.jpg";

export const AboutMe = () => {
  return (
    <section className="about-me" id="about">
      <img className="background-image-about" src={colorSharp} alt="Background" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={profileImage} alt="Profile" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <h2>About Me</h2>
              <p>
                I'm Vikram, a dedicated software engineer with a passion
                for technology since my early days with the PlayStation 3 in
                2008. This childhood fascination ignited my journey into
                software development, where I've honed my skills in coding,
                problem-solving, and innovation. I'm committed to leveraging
                technology for positive change and thrive on creating elegant
                solutions. When I'm not coding, I enjoy exploring the outdoors
                and seeking inspiration from various sources.
              </p>
              <div className="resume-btn">
                <a
                  href="https://drive.google.com/file/d/1VxnyYSYodfaEm86VPn1ecMWvQisUYcy-/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Resume
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
