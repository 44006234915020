import React from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import hideoutImg1 from "../assets/img/hideout-img1.svg";
import hideoutImg2 from "../assets/img/hideout-img2.svg";
import hideoutImg3 from "../assets/img/hideout-img3.svg";
import hideoutImg4 from "../assets/img/hideout-img4.svg";
import hideoutImg5 from "../assets/img/hideout-img5.svg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import omecloneImg1 from "../assets/img/omeclone1.png";
import omecloneImg2 from "../assets/img/omeclone2.png";
import omecloneImg3 from "../assets/img/omeclone3.png";
import wildfireImg1 from "../assets/img/wildfire1.png";
import widlfireImg2 from "../assets/img/wildfire2.png";
import wildfireImg3 from "../assets/img/wildfire3.png";
import wildfireImg4 from "../assets/img/wildfire.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import relayImg1 from "../assets/img/relay-img1.png";
import relayImg2 from "../assets/img/relay-img2.png";
import relayImg3 from "../assets/img/relay-img3.png";
import relayImg4 from "../assets/img/relay-img4.png";
import relayImg5 from "../assets/img/relay-img5.png";
import projectback from "../assets/img/project-bg.svg";


export const Projects = () => {
  const relay = [
    {
      title: "Starting Page",
      description: "Landing Page" ,
      imgUrl: relayImg1,
      githubLink: "https://github.com/harshithl1777/relay",
    },
    {
      title: "Sign Up Page",
      description: "User Sign Up with Social Media Authentication" ,
      imgUrl: relayImg5,
      githubLink: "https://github.com/harshithl1777/relay",
    },
    {
      title: "Session Page",
      description: "Session Page as seen by a User" ,
      imgUrl: relayImg2,
      githubLink: "https://github.com/harshithl1777/relay",
    },
    {
      title: "Attendance Page",
      description: "Log In Attendance Page" ,
      imgUrl: relayImg4,
      githubLink: "https://github.com/harshithl1777/relay",
    },
    {
      title: "Students Page",
      description: "List of Students in a Session" ,
      imgUrl: relayImg3,
      githubLink: "https://github.com/harshithl1777/relay",
    },
  ]
  const hideout = [
    {
      title: "Landing Page",
      description: "Page when the user first visits the website",
      imgUrl: hideoutImg1,
      githubLink: "https://github.com/tryhideout/web",
    },
    {
      title: "Log In Page",
      description: "Page with social media authentication and email/password authentication",
      imgUrl: hideoutImg2,
      githubLink: "https://github.com/tryhideout/web",
    },
    {
      title: "Hideout Join Page",
      description: "Page where the user can join a hideout from a code given by the host",
      imgUrl: hideoutImg3,
      githubLink: "https://github.com/tryhideout/web",
    },
    {
      title: "Chores Page",
      description: "Page where the user can view and add chores to the hideout",
      imgUrl: hideoutImg4,
      githubLink: "https://github.com/tryhideout/web",
    },
    {
      title: "Expenses Page",
      description: "Page where the user can view and add expenses to the hideout",
      imgUrl: hideoutImg5,
      githubLink: "https://github.com/tryhideout/web",
    },
  ];

  const omeclone = [
    {
      title: "Landing Page",
      description: "Initiate Video Chat",
      imgUrl: omecloneImg1,
      githubLink: "https://github.com/999Vik/OmeClone",
    },
    {
      title: "Canvas Interaction",
      description: "Realtime Drawing Board",
      imgUrl: omecloneImg2,
      githubLink: "https://github.com/999Vik/OmeClone",
    },
    {
      title: "Video Chat With Camera, Canvas & Mic",
      description: "Camera and Canvas Interaction",
      imgUrl: omecloneImg3,
      githubLink: "https://github.com/999Vik/OmeClone",
    },
  ]

  const wildfire = [
    {
      title: "Landing Page",
      description: "Initiate Video Chat",
      imgUrl: wildfireImg1,
      githubLink: "https://github.com/999Vik/wildfire"
    },
    {
      title: "Landing Page",
      description: "Initiate Video Chat",
      imgUrl: widlfireImg2,
      githubLink: "https://github.com/999Vik/wildfire"
    },
    {
      title: "Landing Page",
      description: "Initiate Video Chat",
      imgUrl: wildfireImg3,
      githubLink: "https://github.com/999Vik/wildfire"
    },
    {
      title: "Landing Page",
      description: "Initiate Video Chat",
      imgUrl: wildfireImg4,
      githubLink: "https://github.com/999Vik/wildfire"
    }
  ]

  const openGitHubLink = (githubLink) => {
    window.open(githubLink, "_blank");
  };

  return (
    <section className="project" id="project">
      <img className="background-image-project" src={projectback} alt="Background" />

      <Container>
        <img className="background-image-project" src={projectback} alt="Background" />
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Projects</h2>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                    <Nav.Item>
                        <Nav.Link eventKey="first">Relay</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Hideout</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Omeclone</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">Wildfire</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <p>
                          Relay
                        </p>
                        <Row>
                          {relay.map((project, index) => (
                            <ProjectCard
                              key={index}
                              {...project}
                              onClick={() => openGitHubLink(project.githubLink)}
                            />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <p>
                          Hideout offers a comprehensive and invaluable solution
                          for individuals seeking compatible roommates and a
                          harmonious shared living experience. This project was
                          built using React, Ruby on Rails, and MySQL. It
                          securely and efficiently manages expenses, chores, and
                          reminders.
                        </p>
                        <Row>
                          {hideout.map((project, index) => (
                            <ProjectCard
                              key={index}
                              {...project}
                              onClick={() => openGitHubLink(project.githubLink)}
                            />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>
                          Omeclone is a video chat application with an
                          interactive drawing board that updates in real-time.
                          It was developed using Vue, WebRTC, and WebSockets.
                          This application enables users to connect with
                          like-minded individuals online, expanding their social
                          circles.
                        </p>
                        <Row>
                          {omeclone.map((project, index) => (
                            <ProjectCard
                              key={index}
                              {...project}
                              onClick={() => openGitHubLink(project.githubLink)}
                            />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <p>
                          Wildfire is an application designed to boost low
                          conversion rates for businesses. It utilizes HTML,
                          CSS, JavaScript, and Handlebars.js for templating,
                          while the back-end is powered by Node.js, Express, and
                          Cloud Firestore. This innovative solution addresses a
                          common problem faced by companies, helping them
                          enhance their conversion rates through technology and
                          design.
                        </p>
                        <Row>
                          {wildfire.map((project, index) => (
                            <ProjectCard
                              key={index}
                              {...project}
                              onClick={() => openGitHubLink(project.githubLink)}
                            />
                          ))}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>

      <img
        className="background-image-right"
        src={colorSharp2}
        alt="GitHub"
        onClick={() => openGitHubLink("https://github.com/your-github-profile")}
      />
    </section>
  );
};
