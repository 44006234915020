import Carousel from "react-bootstrap/Carousel";
import colorSharp from "../assets/img/color-sharp.svg";

export const Experience = () => {
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // };
  return (
    <section className="experience" id="experience">
      <img
        className="background-image-left"
        src={colorSharp}
        alt="Background"
      />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="experience-bx wow zoomIn">
              <h2>Experience</h2>

              <Carousel>
                <Carousel.Item>
                  <div className="carousel-content text-center">
                    <h5>Bank of Montreal (BMO)</h5>
                    <ul>
                      <li>
                        <p className="reduce-margin">
                          Implemented asynchronous task processing using Celery,
                          Redis, and AJAX to enable real-time communication
                          between both ends of the Django application, enhancing
                          user experience by making updates seamless.
                        </p>
                      </li>
                      <li>
                        <p className="reduce-margin">
                          Containerized the application using Docker,
                          simplifying the deployment process and ensuring
                          consistent environments across development, testing,
                          and production, leading to a 35% smoother
                          collaboration and scalability.
                        </p>
                      </li>
                      <li>
                        <p className="reduce-margin">
                          Optimized backend performance by parallelizing API
                          endpoints and calculations, improving response time
                          and reducing computational overhead, resulting in
                          faster processing by 45% and a more efficient
                          application.
                        </p>
                      </li>
                    </ul>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="carousel-content text-center">
                    <h5>Korah Limited</h5>
                    <ul>
                      <li>
                        <p className="reduce-margin">
                          Utilized Java Selenium to conduct automated tests,
                          ensuring service excellence, and enhancing testing
                          efficiency by 40%.
                        </p>
                      </li>
                      <li>
                        <p className="reduce-margin">
                          Developed a web application using React.js, Node.js,
                          and Express.js to streamline product management.
                        </p>
                      </li>
                      <li>
                        <p className="reduce-margin">
                          Employed Angular to create an intuitive and
                          user-friendly interface for a website chatbot,
                          resulting in faster and more accurate responses.
                          Implemented efficient data tables, boosting the AI
                          chatbot's performance by 20%.
                        </p>
                      </li>
                    </ul>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="carousel-content text-center">
                    <h5>Mitra Biotechnologies</h5>
                    <ul>
                      <li>
                        <p className="reduce-margin">
                          Collaborated with a team of five to develop a React
                          and Bootstrap-based web application, promoting the
                          company's visibility.
                        </p>
                      </li>
                      <li>
                        <p className="reduce-margin">
                          Designed a React Native mobile app addressing the
                          global doctor shortage, enabling efficient
                          pre-diagnosis and connecting users with healthcare
                          professionals. The app also garnered attention from
                          medical professionals due to its blood marker
                          detection feature.
                        </p>
                      </li>
                    </ul>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="carousel-content text-center">
                    <h5>Qoom.io</h5>
                    <ul>
                      <li>
                        <p className="reduce-margin">
                          Enhanced Qoom's IDE functionality and performance
                          through testing and optimization efforts, resulting in
                          a 30% faster code formatting speed.
                        </p>
                      </li>
                      <li>
                        <p className="reduce-margin">
                          Led the testing and evaluation of applications
                          developed by interns to expand Qoom's project
                          portfolio.
                        </p>
                      </li>
                      <li>
                        <p className="reduce-margin">
                          Developed a web chat application facilitating
                          real-time communication and collaboration among users
                          with shared interests, incorporating features such as
                          live chat, real-time drawing, and interactive
                          activities.
                        </p>
                      </li>
                    </ul>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="carousel-content text-center">
                    <h5>CheaprEats</h5>
                    <ul>
                      <li>
                        <p className="reduce-margin">
                          Employed MongoDB, GraphQL, and TypeScript to implement
                          and test a database for a web application.
                        </p>
                      </li>
                      <li>
                        <p className="reduce-margin">
                          Conducted API testing using Postman to validate
                          CheaprEats' functionality and usability.
                        </p>
                      </li>
                      <li>
                        <p className="reduce-margin">
                          Applied strong problem-solving and analytical skills
                          to identify and troubleshoot database and API issues.
                        </p>
                      </li>
                    </ul>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
